import React, { Component, Fragment } from "react";
import DayCard from "../menuComponents/commonComponents/dayCard";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import PopOverForTimeValidation from "./commonComponents/popOverForTimeValidation";
import { deleteSelectedTaskData, getErrorMessage } from "../../utils/utils";
import warningIcon from "../../logo/warning.png";

import {
  ALT_KEY_CODE,
  blockInvalidChar,
  validation1,
} from "../../utils/validations";

import { DropdownButton, OverlayTrigger, Tooltip } from "react-bootstrap";
import TaskDoneWarningModel from "./commonComponents/TaskDoneWarningModel";

import { updateTaskDone } from "../../utils/services";

const target = HTMLInputElement;

class TimeEntryMainPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {
        status: false,
        message: "",
      },
      selectedOptions: [],
      isVisible: false,
      ref: null,
      toggleText: "Select Project",

      selectedProjectsNames: "All",
      /**
       *  projectName_All -> value required for name of "All" Checkbox of project dropdown
       * projectName_All.isSelected -> determine the checked and unchecked conditions of "All" checkbox <- value for the property of "checked" of <Form.Check> element of  <Dropdown.Item> of "All"
       * projectName_All.allSelected -> value required to identify -> All selected true/false condition [ allSelected === true ->condition of both all selected checkboxes and all unselected/unchecked checkboxes  [NOTE : all timesheets which available tasks are viewe/display on this condition] ]
       */
      projectName_All: {
        projectName: "All",
        isSelected: true,
        allSelected: true,
      },
      selectedProjetId: "",
      selectedTaskData: [],
      isTaskDone: false,
      taskDoneIds: [],
      deleteProjectId: null,
      alertForTimeType: false,

      dropDownClassName: "projectlist_dropdown_menu dropdown-menu show",
    };
    // create a ref to store the textInput DOM element
    this.textInput = React.createRef();
    // this.focusTextInput = this.focusTextInput.bind(this);
    this.myRef = React.createRef();
    // Reference to the dropdown
    this.dropdownRef = React.createRef();
  }

  componentDidMount() {
    // Add a click event listener to the document
    document.addEventListener("click", this.handleDocumentClick);
    document.addEventListener("focusout", this.handleDocumentClick);
  }

  componentWillUnmount() {
    // Remove the click event listener when the component is unmounted
    document.removeEventListener("click", this.handleDocumentClick);
    document.addEventListener("focusout", this.handleDocumentClick);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedDate?.week !== this.props.selectedDate?.week) {
      this.setState({
        selectedProjectsNames: "All",

        projectName_All: {
          projectName: "All",
          isSelected: true,
          allSelected: true,
        },
      });
    }
  }

  handleDocumentClick = (event) => {
    // Check if the click event target is outside of the dropdown
    if (
      this.dropdownRef.current &&
      !this.dropdownRef.current.contains(event.target)
    ) {
      // Set the isOpen state to true when clicking outside of project dropdown
      this.setState({
        dropDownClassName: "projectlist_dropdown_menu dropdown-menu",
      });
    }
  };

  /**
   * Dropdown checkbox selection handler
   */
  handleOptionToggle = (option) => {
    this.setState({
      dropDownClassName: "projectlist_dropdown_menu dropdown-menu show",
    });

    /**
     * option.projectName === "All" === true -> handle checkbox selection of "All" <- "All" Checkbox name
     *  option.projectName === "All" === false -> handle checkbox selection of others <- checkboxes of project list
     */

    if (option.projectName === "All") {
      this.handleSelectionOfCheckBoxOfAll(!option.isSelected);
    } else {
      /**
       * this.state.projectName_All?.allSelected === true -> condition 1 -> just after selection of "All"
       * this.state.projectName_All?.allSelected === false -> condition 2 -> selection of checkboxes of projects ->except condition 1
       */

      if (this.state.projectName_All?.allSelected) {
        this.handleAllselectedTrueCondition(
          option,
          this.state.projectName_All?.isSelected
        );
      } else {
        if (!option.isSelected) {
          let userTimeSheetData =
            this.updateSelectedProjectIsViewedStateofUserTimeSheet(
              option,
              this.props.userTimeSheetData
            );
          let projectList =
            this.updateSelectedProjectIsSelectedStateofProjectList(
              option,
              this.props.projectList
            );
          let selectedProjectsNames = this.getSelectedProjectsName(projectList);
          this.updateSelectedProjectsNames(selectedProjectsNames);
          this.handleAllSelectedTrue_byUser(userTimeSheetData);
        } else {
          let userTimeSheetData =
            this.updateSelectedProjectIsViewedStateofUserTimeSheet(
              option,
              this.props.userTimeSheetData
            );
          let projectList =
            this.updateSelectedProjectIsSelectedStateofProjectList(
              option,
              this.props.projectList
            );
          this.handleAllSelectedFalse_byUser(userTimeSheetData);
          let selectedProjectsNames = this.getSelectedProjectsName(projectList);
          this.updateSelectedProjectsNames(selectedProjectsNames);
        }
      }
    }
  };

  getSelectedProjectsName = (projectList) => {
    let projectListName = "";
    let n = 0;
    projectList?.map((project) => {
      if (project?.isSelected) {
        n++;
        if (n === 1) {
          projectListName = projectListName + project?.name;
        } else {
          projectListName = projectListName + "; " + project?.name;
        }
      }
    });
    return projectListName === "" ? "Select Options" : projectListName;
  };

  /**
   * Situation of all unselected by user-> when ->one by one unselection of checkboxes of project list
   * @param {*} userTimeSheetData -> updated userTimeSheetData value
   *
   */
  handleAllSelectedFalse_byUser = (userTimeSheetData) => {
    let isViewed = false; // if all checkboxes are de-selected "isViewed" keep as false
    let isAllViewedTrue = true; // if  all checkboxes are sselected-> isAllViewedTrue value remains same -> true
    userTimeSheetData?.map((timesheet) => {
      timesheet.isViewed && (isViewed = true); // break  [view all condition fail]
      !timesheet.isViewed && (isAllViewedTrue = false); // break -> fail selected all conditon
    });
    if (!isViewed) {
      this.handleSelectionOfCheckBoxOfAll(false);
    }
    if (isAllViewedTrue) {
      this.setState({
        selectedProjectsNames: "All",
      });
    }
  };

  handleAllSelectedTrue_byUser = (userTimeSheetData) => {
    let isViewed = true;
    userTimeSheetData?.map((project) => {
      !project.isViewed && (isViewed = false);
    });
    if (isViewed) {
      this.handleSelectionOfCheckBoxOfAll(true);
    }
  };

  /**
   * handleAllselectedTrueCondition Method -> handle the behavior of  isSelected value of projectlist and isViewed value of userTimeSheetData
   * @param {*} option -> value of selected or unselected project's checkbox
   * @param {*} isSelected_All_Checkbox -> value of checkbox of "All"
   */
  handleAllselectedTrueCondition = (option, isSelected_All_Checkbox) => {
    this.setAllSelectedFalseOfProjectName_All();
    let userTimeSheetData;
    if (isSelected_All_Checkbox) {
      userTimeSheetData = this.props.userTimeSheetData;
    } else {
      userTimeSheetData = this.updateIsViewedStateofUserTimeSheet(false);
    }

    this.updateSelectedProjectIsViewedStateofUserTimeSheet(
      option,
      userTimeSheetData
    );
    let projectlist = this.updateSelectedProjectIsSelectedStateofProjectList(
      option,
      this.props.projectList
    );
    let selectedProjectsNames = this.getSelectedProjectsName(projectlist);
    this.updateSelectedProjectsNames(selectedProjectsNames);
  };
  updateSelectedProjectsNames = (name) => {
    this.setState({
      selectedProjectsNames: name,
    });
  };
  handleSelectionOfCheckBoxOfAll = (isSelected) => {
    if (isSelected) {
      this.updateSelectedProjectsNames("All");
    } else {
      //if all checkboxes are unSelect  -> name of dropdown is ""
      this.updateSelectedProjectsNames("All");
    }
    this.updateIsViewedStateofUserTimeSheet(true); // for both of all selected and all unselected condition -> [view all condition] -> display all timesheets whcih task available
    this.updateIsSelectedStateOfProjectList(isSelected);
    this.updateProjectName_AllState(isSelected);
  };

  updateIsViewedStateofUserTimeSheet = (value) => {
    let userTimeSheetData = this.props.userTimeSheetData?.map((project) => {
      return {
        ...project,
        isViewed: value,
        viewOnLoad:
          project?.taskData?.length > 0
            ? true
            : project.gteProjectId === -1
              ? true
              : false,
      };
    });
    this.props.updateStateOfUserTimeSheetData(userTimeSheetData);
    return userTimeSheetData;
  };
  updateIsSelectedStateOfProjectList = (value) => {
    let projectList = this.props.projectList?.map((project) => {
      return {
        ...project,
        isSelected: value,
      };
    });
    this.props.updateStateOfProjectList(projectList);
    return projectList;
  };

  updateProjectName_AllState = (value) => {
    let projectName_All = this.state.projectName_All;
    projectName_All.isSelected = value;
    projectName_All.allSelected = true;
    this.setState({
      projectName_All: projectName_All,
    });
  };
  /**
   *    projectName_All.isSelected = false; <- deselect the checkbox of "All"
   */
  setAllSelectedFalseOfProjectName_All = () => {
    let projectName_All = this.state.projectName_All;
    projectName_All.allSelected = false;
    projectName_All.isSelected = false;
    this.setState({
      projectName_All: projectName_All,
    });
  };

  /* Requirement of "return userTimeSheetData;" of the method ->
  // if we do quick changes -> eg call twice in same method which used to update props values 
  //such as "updateSelectedProjectIsViewedStateofUserTimeSheet" -> we can get unexpected result (bcz get some time for updating status)
  // for this moment we can use return value of those methods instead of using props values
  */
  updateSelectedProjectIsViewedStateofUserTimeSheet = (
    option,
    userTimeSheetData
  ) => {
    userTimeSheetData = userTimeSheetData?.map((project) => {
      if (project.gteProjectId === option.gteProjectId) {
        return {
          ...project,
          isViewed: !option.isSelected, //isViewed -> current conditon of relevent checkbox
          viewOnLoad: !option.isSelected, //viewOnLoad -> default value is true -> all timesheets which available task should diplay in the conditon of onLoading
        };
      } else {
        return project;
      }
    });
    this.props.updateStateOfUserTimeSheetData(userTimeSheetData);
    return userTimeSheetData;
  };
  /**
   *
   * @param {*} option -> selected or deselected checkbox value
   * @param {*} projectList -> current project list value
   * @returns -> updated project list
   */
  updateSelectedProjectIsSelectedStateofProjectList = (option, projectList) => {
    projectList = projectList?.map((project) => {
      if (project.gteProjectId === option.gteProjectId) {
        return { ...project, isSelected: !option.isSelected };
      } else {
        return project;
      }
    });
    this.props.updateStateOfProjectList(projectList);
    return projectList;
  };

  focusTextInput() {
    // Explicitly focus the text input using the raw DOM API
    // Note: we're accessing "current" to get the DOM node
    // this.textInput.current.focus();
    const element = this.textInput.current;
  }

  handleTogle = (option) => {
    const { selectedOptions } = this.state;
    const index = selectedOptions.findIndex(
      (selectedOption) => selectedOption.gteProjectId === option.gteProjectId
    );
    if (index > -1) {
      selectedOptions.splice(index, 1);
    } else {
      selectedOptions.push(option);
    }
    this.setState({ selectedOptions });
  };
  toggleVisibility = () => {
    this.setState((prevState) => ({
      isVisible: !prevState.isVisible,
    }));
  };

  updateSelectedProjectAllState = (value) => {
    let projectName_All = this.state.projectName_All;
    projectName_All.isSelected = value;
    this.setState({
      selectedProjectsNames: "All",
      projectName_All: projectName_All,
    });
  };

  handleChange(e) {
    this.setState({ inputValue: e.target.value });
  }
  calTotalHoursForCurrentWeek = () => {
    let total = 0;
    this.props.userTimeSheetData?.map((project) => {
      if (project.isViewed) {
        project.taskData?.map((task) => {
          total = total + task.totalTimeForTask;
        });
      }
    });
    total = Math.round((total + Number.EPSILON) * 100) / 100;
    return total.toFixed(2);
  };

  handleTaskAssignmentStatus = (
    e,

    task,
    gteProjectId
  ) => {
    let userTimeSheetData = this.props.userTimeSheetData?.map((project) => {
      if (project.gteProjectId === gteProjectId) {
        let taskData = project.taskData?.map((taskdata) => {
          if (taskdata.taskId === task.taskId) {
            this.setState({
              selectedTaskData: {
                ...taskdata,
                taskAssignmentStatus: e.target.value,
              },
            });

            if (e.target.value === "Done") {
              this.props.updateStateofIsTaskDone(true);
              // this.setState({
              //   isTaskDone: true,
              // });
            }
            // this.saveWipDoneChange(
            //   {
            //     ...taskdata,
            //     taskAssignmentStatus: e.target.value,
            //   }
            // );
            return {
              ...taskdata,
              taskAssignmentStatus: e.target.value,
              isChanged: true,
              isChangedWIPStatus: true, //---------------------------------- req for api call for WIP in saveDraft Method
            };
          } else {
            return taskdata;
          }
        });

        return { ...project, taskData: taskData, isChanged: true };
      } else {
        return project;
      }
    });

    this.setState({
      userTimeSheetData: userTimeSheetData,
    });
    this.props.updateStateOfUserTimeSheetData(userTimeSheetData);
  };

  saveWipDoneChange(taskDataObj) {
    console.log("taskDataObj  ", taskDataObj);
    // let promises = [];
    let value = {
      userStatus:
        taskDataObj?.taskAssignmentStatus === "WIP"
          ? "I"
          : taskDataObj?.taskAssignmentStatus === "Done" && "R",
      lastUpdatedBy: this.props.loggedUserGteEmployeeNo,
      attribute15: "W-TE-SC",
    };
    updateTaskDone(taskDataObj?.taskAssignmentId, value);
    // promises.push(
    //   updateTaskDone(taskDataObj.taskAssignmentId, value)
    // );
  }

  handleTashButton = (task, timeSheet) => {
    let value = {
      gteProjectId: timeSheet.gteProjectId,
      taskAssignmentId: task.taskAssignmentId,
      timeSheetRecordId: task.timeSheetRecordId,
      loggedUserGteEmployeeId: this.props.loggedUserGteEmployeeNo,
      operationalUserGteEmployeeId: this.props.operationalUserGteEmployeeNo,
    };
    let selectedTaskValue = {
      gteProjectId: 0,
      timeSheet: timeSheet,
      task: {},
      timedate: {},
      newTimetotal: 0,
    };

    this.deleteTaskData(timeSheet.gteProjectId, value, selectedTaskValue);
  };

  deleteTaskData(gteProjectId, value, selectedTaskValue) {
    deleteSelectedTaskData(value)
      .then((response) => {
        this.setState({});
        this.props.updateSelectedTaskValue(selectedTaskValue);
        this.props.refreshTimeSheetData(
          this.props.selectedDate,
          this.props.operationalUserGteEmployeeNo,
          gteProjectId
        );
        this.props.deleteTaskData(selectedTaskValue, gteProjectId);
      })
      .catch((error) => {
        let message = getErrorMessage(error);
        this.setState({
          loading: false,
          error: {
            status:
              message?.status === 401
                ? false
                : message?.status === 404
                  ? false
                  : true,
            message: message,
          },
        });
      });
  }

  handleSelectInput(event) { }

  /**
   * highlightSelectedInput()-> highligted selected time slot ->its captured by the "isSelected: true".
   *
   * From this method update the state of "isSelected" as true of relevenet task
   * [captured by the "taskAssignmentId", "date" of timeDate obj and "gteProjectId"
   * passed as the parameter from selected element]
   */
  highlightSelectedInput(value, gteProjectId, task, timedate) {
    if (this.props.clickInput) {
      let userTimeSheetData = this.props.userTimeSheetData?.map((project) => {
        if (project.gteProjectId === gteProjectId) {
          let taskData = project.taskData?.map((taskdata) => {
            if (taskdata?.taskAssignmentId === task?.taskAssignmentId) {
              let timeDate = taskdata?.timeDate?.map((timeentrydate) => {
                if (timeentrydate.date === timedate.date) {
                  return {
                    ...timeentrydate,

                    isSelected: value,
                  };
                } else {
                  return { ...timeentrydate, isSelected: false };
                }
              });
              return {
                ...taskdata,
                timeDate: timeDate,
              };
            } else {
              return taskdata;
            }
          });

          return { ...project, taskData: taskData };
        } else {
          return project;
        }
      });

      this.props.updateStateOfUserTimeSheetData(userTimeSheetData);
    }
  }

  onKeyUpFunction(event) { }
  handleNonProjectAddTaskButton = (rightPanelDivClassNameProperty) => {
    this.showAddTaskPanel(rightPanelDivClassNameProperty);
  };

  showAddTaskPanel(rightPanelDivClassNameProperty) {
    rightPanelDivClassNameProperty.alerts = {
      button: "accordion-button collapsed ",
      div: "accordion-collapse collapse",
    };
    rightPanelDivClassNameProperty.addTask = {
      button: "accordion-button",
      div: "accordion-collapse collapse show",
    };
    rightPanelDivClassNameProperty.selectedTask = {
      button: "accordion-button collapsed",
      div: "accordion-collapse collapse",
    };
    this.props.updateStateOfRightPanelDivClassNameProperty(
      rightPanelDivClassNameProperty
    );
  }

  renderInputComponent(timeSheet, task, day) {
    return (
      <input
        tabIndex={0}
        title={`${task?.timeDate?.find(({ date }) => date === day?.date)?.totalTime
          } hours for the task of ${task?.taskName} on ${day?.date}`}
        className={`timeSlot ${!task?.timeDate?.find(({ date }) => date === day?.date)
          ?.istimeTypeEnableProject
          ? task?.timeDate?.find(({ date }) => date === day?.date)
            ?.timeEntry[0].timeEntryPendingStatus === "A" ||
            task?.timeDate?.find(({ date }) => date === day?.date)
              ?.timeEntry[0].timeEntryPendingStatus === "R"
            ? "PS"
            : task?.timeDate?.find(({ date }) => date === day?.date)
              ?.timeEntry[0].timeEntryStatus
          : ""
          } ${!task?.timeDate?.find(({ date }) => date === day?.date)
            ?.istimeTypeEnableProject &&
            task?.timeDate?.find(({ date }) => date === day?.date)?.isFreezed ===
            "Y"
            ?  ( day.date <= this.props.freezeDateData?.configValue 
              && this.props.freezeDateData?.isFreezed)
              ? "quater_freeze"
              : "week_freeze"
            : ""
          } ${task?.istimeTypeEnableProject &&
            timeSheet?.gteProjectId ===
            this.props.selectedTaskValue?.gteProjectId &&
            task?.taskId === this.props.selectedTaskValue?.task?.taskId &&
            day?.date === this.props.selectedTaskValue?.timedate?.date
            ? "active"
            : ""
          }`}
        style={{}}
        value={
          task?.timeDate?.find(({ date }) => date === day?.date)?.totalTime
        }
        disabled={
          //------------------------> if timeTimeEnable  -> cannot disable
          !task?.timeDate?.find(({ date }) => date === day?.date)
            ?.istimeTypeEnableProject &&
          (task?.timeDate?.find(({ date }) => date === day?.date)?.timeEntry[0]
            .timeEntryStatus === "B" ||
            task?.timeDate?.find(({ date }) => date === day.date)?.timeEntry[0]
              .timeEntryStatus === "A" ||
            task?.timeDate?.find(({ date }) => date === day?.date)?.isFreezed ===
            "Y"
            ? true
            : false)
        }
        readOnly={
          task?.timeDate?.find(({ date }) => date === day?.date)
            ?.istimeTypeEnableProject
            ? true
            : false
        }
        onBlur={(event) =>
          this.props.validateTimeEntered(
            event.target.value,
            timeSheet.gteProjectId,
            task,
            task?.timeDate?.find(({ date }) => date === day?.date)
          )
        }
        onChange={(event) => {
          this.props.addTimeEntered(
            event.target.value,
            timeSheet.gteProjectId,
            task,
            task?.timeDate?.find(({ date }) => date === day?.date)
          );
        }}
        onKeyUp={(e) => {
          if (blockInvalidChar.includes(e.key)) {
            e.target.value = "";
          }
        }}
        onPaste={(e) => {
          e.preventDefault();
          return false;
        }}
        onCopy={(e) => {
          e.preventDefault();
          return false;
        }}
        ref={(inputElement) => {
          if (inputElement) {
            if (
              !task?.timeDate?.find(({ date }) => date === day?.date)
                ?.istimeTypeEnableProject
            ) {
              if (
                task?.timeDate?.find(({ date }) => date === day?.date)
                  ?.timeError
              ) {
                inputElement.focus();
                inputElement.select();
              }
              if (
                !task?.timeDate?.find(({ date }) => date === day?.date)
                  ?.timeError &&
                task?.timeDate?.find(({ date }) => date === day?.date)
                  ?.isSelected
              ) {
                inputElement.select();
              }
              if (
                task?.timeDate?.find(({ date }) => date === day?.date)
                  ?.timeEntry[0].focused
              ) {
                inputElement.focus();
              }
            }
          }
        }}
        onClick={() => {
          this.highlightSelectedInput(
            true,

            timeSheet.gteProjectId,
            task,
            task?.timeDate?.find(({ date }) => date === day?.date)
          );
          this.props.setSelectedTaskPanelValue(
            timeSheet,
            timeSheet.gteProjectId,
            task,
            task?.timeDate?.find(({ date }) => date === day?.date)
          );
        }}
        id={
          task?.timeDate?.find(({ date }) => date === day?.date)?.timeRecordId
        }
      />
    );
  }
  renderStatusForTooltip(task, day) {
    return (
      <>
        {task?.timeDate?.find(({ date }) => date === day?.date)?.isFreezed ===
          "Y" && (
            <>
              <strong>Time Restriction:</strong>{" "}
              <span>
                {task?.timeDate?.find(({ date }) => date === day?.date)
                  ?.isFreezed === "Y"
                  && ( day.date <= this.props.freezeDateData?.configValue 
                    && this.props.freezeDateData?.isFreezed)
                  ? " Quarter Time Frozen"
                  : (task?.timeDate?.find(({ date }) => date === day?.date)?.isFreezedMessage !== null) ?
                    task?.timeDate?.find(({ date }) => date === day?.date)?.isFreezedMessage : " Weekly Time Frozen"}
              </span>
              <br></br>
            </>
          )}
        {!(
          task?.timeDate?.find(({ date }) => date === day?.date)?.timeEntry[0]
            .timeEntryStatus === "S" &&
          task?.timeDate?.find(({ date }) => date === day?.date)?.totalTime ===
          0
        ) && (
            <>
              <strong>Time Status: </strong>{" "}
              <span>
                {(task?.timeDate?.find(({ date }) => date === day?.date)
                  ?.timeEntry[0].timeEntryPendingStatus === "A" || task?.timeDate?.find(({ date }) => date === day?.date)
                    ?.timeEntry[0].timeEntryPendingStatus === "R") ? "Time Under Review for Approval" :
                  task?.timeDate?.find(({ date }) => date === day?.date)
                    ?.timeEntry[0].timeEntryStatus === "U"
                    ? "Time Submitted for Approval"
                    : task?.timeDate?.find(({ date }) => date === day?.date)
                      ?.timeEntry[0].timeEntryStatus === "A"
                      ? "Time Approved by Time Approver"
                      : task?.timeDate?.find(({ date }) => date === day?.date)
                        ?.timeEntry[0].timeEntryStatus === "R"
                        ? "Time Rejected by Time Approver"
                        : task?.timeDate?.find(({ date }) => date === day?.date)
                          ?.timeEntry[0].timeEntryStatus === "B"
                          ? "Cell Blocked due to the end of the Project/Allocation/Task or Task Assignment"
                          : task?.timeDate?.find(({ date }) => date === day?.date)
                            ?.timeEntry[0].timeEntryStatus === "S" && "Time Saved"}
              </span>
            </>
          )}
      </>
    );
  }
  entering = (e) => {
    e.children[1].style.backgroundColor = "#2b7bcc";
    e.children[1].style.color = "#fff";
    e.children[1].style.borderColor = "#2b7bcc";
  };
  render() {
    const { ref } = this.state;

    const tooltip = (props) => (
      <Tooltip
        id="tooltipForRightPanel"
        className="time_type_status_tooltip"
        {...props}
      >
        USE RIGHT PANEL TO ENTER TIME
      </Tooltip>
    );

    // Set your color here

    let styleForTooltip = {
      background: "linear-gradient(0deg, #403fe2, #4a7bb6)",
      color: "#fff",
      border: "1px solid #2b7bcc",
      borderRadius: "5px",
      fontSize: "10px",
    };
    return (
      <React.Fragment>
        <div
          className="ch-task-content"
          alt={`This table contain all the task available for ${this.props.selectedDate.dateOfFirstDayofCurrentWeek} to
           ${this.props.selectedDate.dateOfLastDayofCurrentWeek}`}
        >
          {((this.props.freezeDateData?.isUpdated && this.props.freezeDateData?.isFreezed)
         || (this.props.pastWeekLeaveMismatchesDetails?.status === "Fail")
        //  ||  (!this.props.pastWeekLeaveMismatchesDetails?.status === "Success")
        )
            && (
              <div className="ch-comm-notification">
                {this.props.freezeDateData?.isUpdated && this.props.freezeDateData?.isFreezed && (
                  <span tabIndex={0}>
                    <span><img
                      src={warningIcon}
                      style={{
                        width: "20px",
                        height: "20px",
                        padding: "2px",
                        display: "inline-block",
                        marginRight: "5px",
                        marginBottom: "5px"
                      }}
                      alt="Warning Icon"
                    /></span>
                    All Time Sheets up to {this.props.freezeDateData?.configValue}{" "}
                    are now permanently frozen and cannot be unfrozen using the
                    above ‘Unfreeze My Week’ button. <br />
                  </span>
                )}
                {this.props.pastWeekLeaveMismatchesDetails?.isUpdated &&
                  this.props.pastWeekLeaveMismatchesDetails?.status === "Fail" && (
                    <span className=""><img
                      src={warningIcon}
                      style={{
                        width: "20px",
                        height: "20px",
                        padding: "2px",
                        display: "inline-block",
                        marginRight: "5px",
                        marginBottom: "5px"
                      }}
                      alt="Warning Icon"
                    />
                      <span>
                        {this.props.pastWeekLeaveMismatchesDetails?.message}
                        {this.props.pastWeekLeaveMismatchesDetails?.dates?.map((item) => item).join(", ")}.
                       
                        {this.props.pastWeekLeaveMismatchesDetails?.message && this.props.pastWeekLeaveMismatchesDetails?.message === "You have entered leave time in CheckIn and haven't applied for absence. Dates: " && (
                  
                  <a
                  className="pastWkLeave-link"
                   target="_blank"
                   href="https://ecgy.fa.ap2.oraclecloud.com/fscmUI/faces/deeplink?objType=ADD_ABSENCE&action=NONE"
                  >   Click To Apply Absence</a>
                )}
                       
                       
                       
                       
                       
                       
                      </span>
                    </span>
                  )} 
              </div>
            )}

          <div className="ch-task-container">
            <div className="ch-row">
              <div className="ch-time-detail-col">
                <div class="total-hr">
                  <label alt="My Total Hours For This Week" tabIndex={0}>
                    MY TOTAL HOURS FOR THIS WEEK
                  </label>
                  <div
                    tabIndex={0}
                    class="hour-count"
                    alt={`${this.calTotalHoursForCurrentWeek} hours`}
                  >
                    {" "}
                    {this.calTotalHoursForCurrentWeek()}
                  </div>
                  <span tabIndex={0} alt={`hours`}>
                    Hours
                  </span>
                </div>

                <div className="">
                  <Dropdown
                    // tabIndex={0}
                    title={`selected ${this.state.selectedProjectsNames} project list`}
                    size=""
                    className="projetlist_dropdown"
                    ref={this.dropdownRef}
                  >
                    <Dropdown.Toggle
                      variant=""
                      className="projectlist_toggle"
                      title={`selected ${this.state.selectedProjectsNames} project list`}
                    >
                      {this.state.selectedProjectsNames}
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className={this.state.dropDownClassName}
                    // alt="dropdown-multiselect"
                    >
                      <Dropdown.Item
                        // key="-1"
                        id={-1}
                        eventKey={-1}
                        value="-1"
                        onClick={() =>
                          this.handleOptionToggle(this.state.projectName_All)
                        }
                        className=""
                        title={`${this.state.projectName_All.isSelected
                          ? "select"
                          : "unselect"
                          } ${this.state.projectName_All.projectName}`}
                      // alt="select or unselect all"
                      >
                        <Form.Check
                          type="checkbox"
                          id={-1}
                          label={this.state.projectName_All.projectName}
                          // title={`${this.state.projectName_All.projectName} is ${this.state.projectName_All.isSelected?"selected":"not selected"}`}
                          value={-1}
                          checked={this.state.projectName_All.isSelected}
                          disabled={true}
                        />
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      {this.props.projectList?.map((option) => (
                        <Dropdown.Item
                          id={option.gteProjectId}
                          eventKey={option.gteProjectId}
                          onClick={() => this.handleOptionToggle(option)}
                          label={option.name}
                          title={`${option?.isSelected ? "select" : "unselect"
                            } ${option?.name}`}
                        >
                          <Form.Check
                            type="checkbox"
                            id={option.gteProjectId}
                            label={option.name}
                            checked={option.isSelected}
                            onChange={() => this.handleOptionToggle(option)}
                            disabled={true}
                          />
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              {this.props.selectedDate.days?.map((day) => (
                <div className="ch-col8">
                  <DayCard
                    key={day.date}
                    day={day}
                    selectedDate={this.props.selectedDate}
                  />
                </div>
              ))}
            </div>

            <div className="task-block" alt="user time sheet panel">
              <div
                class="ch-row"
              // style={{ height: "60vh", overflowY: "auto" }}
              >
                <table className="ch-time-table" role="presentation">
                  <tbody className="table_scroll_div">
                    {this.props.userTimeSheetData?.length > 0 ? (
                      this.props.userTimeSheetData?.map(
                        (timeSheet) =>
                          timeSheet?.viewOnLoad &&
                          timeSheet?.isViewed && (
                            <div id={timeSheet?.gteProjectId}>
                              {(timeSheet?.gteProjectId === -1 ||
                                (timeSheet?.isViewed &&
                                  timeSheet?.display)) && (
                                  <tr
                                    className="project-name"
                                    id={timeSheet?.gteProjectId}
                                    alt={`panel of ${timeSheet?.projectName}`}
                                  >
                                    <td
                                      className=""
                                      tabIndex={0}
                                      id={timeSheet?.gteProjectId}
                                      data-toggle="tooltip"
                                      data-placement="left"
                                      title={timeSheet?.projectName}
                                      alt={timeSheet?.projectName}
                                    >
                                      {timeSheet?.projectName}

                                      {/* -{timeSheet.gteProjectId} */}
                                      {timeSheet.gteProjectId === -1 && (
                                        <button
                                          tabIndex={0}
                                          className="button btn-sm add-non-project"
                                          data-toggle="tooltip"
                                          data-placement="left"
                                          title="Open Add Task Accordion panel"
                                          alt="Open Add Task Accordion panel"
                                          onClick={() =>
                                            this.handleNonProjectAddTaskButton(
                                              this.props
                                                .rightPanelDivClassNameProperty
                                            )
                                          }
                                        >
                                          &#10010;
                                        </button>
                                      )}
                                    </td>
                                    <td className=""></td>
                                    <td className=""></td>
                                    <td className=""></td>
                                    <td className=""></td>
                                    <td className=""></td>
                                    <td className=""></td>
                                    <td className=""></td>
                                    <td className=""></td>
                                  </tr>
                                )}
                              {timeSheet?.taskData?.map(
                                (task) =>
                                  !(task?.timeSheetRecordStatus === "H") && (
                                    <tr
                                      className="task_data"
                                      id={task?.taskAssignmentId}
                                    >
                                      <td className="">
                                        <tr>
                                          <td>
                                            {task.taskAssignmentSource !==
                                              "SWIFT" ? (
                                              !task.isTimeEntryStatusAsA && (
                                                <div className="col-3">
                                                  <button
                                                    tabIndex={0}
                                                    class="trash btn btn-sm"
                                                    title="delete task button"
                                                    onClick={() =>
                                                      this.handleTashButton(
                                                        task,
                                                        timeSheet
                                                      )
                                                    }
                                                  ></button>
                                                </div>
                                              )
                                            ) : (
                                              <div
                                                className="col-3 dropdown"
                                                alt="select options of work in progress or done "
                                              >
                                                <select
                                                  tabIndex={0}
                                                  title={`${task.taskAssignmentStatus ===
                                                    "I"
                                                    ? "Work In Progress"
                                                    : "Done"
                                                    }`}
                                                  key="wip"
                                                  disabled={
                                                    task?.taskAssignmentStatus ===
                                                      "D"
                                                      ? true
                                                      : false
                                                  }
                                                  className="wip-dropdown"
                                                  value={
                                                    task?.taskAssignmentStatus
                                                  }
                                                  onChange={(e) =>
                                                    this.handleTaskAssignmentStatus(
                                                      e,
                                                      task,
                                                      timeSheet.gteProjectId
                                                    )
                                                  }
                                                >
                                                  {/* There is two option -> 1st one get the value of task.taskAssignmentStatus as default Value, if its -> WIP, then next option should be "Done" */}
                                                  <option
                                                    defaultValue={
                                                      task.taskAssignmentStatus
                                                    }
                                                  >
                                                    {task.taskAssignmentStatus ===
                                                      "I"
                                                      ? "WIP"
                                                      : "Done"}
                                                  </option>
                                                  {/* as logic it should get opposite value of "taskAssignmentStatus" */}
                                                  <option
                                                    key={
                                                      task.taskAssignmentStatus ===
                                                        "I"
                                                        ? "Done"
                                                        : "WIP"
                                                    }
                                                    value={
                                                      task.taskAssignmentStatus ===
                                                        "I"
                                                        ? "Done"
                                                        : "WIP"
                                                    }
                                                  >
                                                    {task.taskAssignmentStatus ===
                                                      "I"
                                                      ? "Done"
                                                      : "WIP"}
                                                  </option>
                                                </select>
                                              </div>
                                            )}
                                          </td>
                                          <td
                                            tabIndex={0}
                                            className="task-name"
                                            // data-toggle="tooltip"
                                            // data-placement="left"
                                            title={task?.taskName}
                                          // alt={task?.taskName}
                                          >
                                            <span>{task?.taskName}</span>
                                          </td>
                                        </tr>
                                      </td>

                                      {/* <div className="row seven-cols"> */}
                                      {this.props.selectedDate.days?.map(
                                        (day) => (
                                          <td
                                            className=""
                                            id={
                                              "timeRecId: " +
                                              task?.timeDate?.find(
                                                ({ date }) => date === day.date
                                              )?.timeRecordId
                                            }
                                          >
                                            <Fragment>
                                              {task?.timeDate?.find(
                                                ({ date }) => date === day?.date
                                              )?.istimeTypeEnableProject ? (
                                                <span className="">
                                                  <OverlayTrigger
                                                    id={
                                                      "timeRecId: " +
                                                      task?.timeDate?.find(
                                                        ({ date }) =>
                                                          date === day.date
                                                      )?.timeRecordId
                                                    }
                                                    trigger={["hover", "focus"]}
                                                    placement="top"
                                                    overlay={tooltip}
                                                    onEntering={(e) => {
                                                      this.entering(e);
                                                    }}
                                                  >
                                                    {this.renderInputComponent(
                                                      timeSheet,
                                                      task,
                                                      day
                                                    )}
                                                  </OverlayTrigger>
                                                </span>
                                              ) : (task?.timeDate?.find(
                                                ({ date }) =>
                                                  date === day?.date
                                              )?.timeEntry[0]?.isChanged &&
                                                task?.timeDate?.find(
                                                  ({ date }) =>
                                                    date === day?.date
                                                )?.timeEntry[0]
                                                  ?.initialTime !==
                                                task?.timeDate?.find(
                                                  ({ date }) =>
                                                    date === day?.date
                                                )?.timeEntry[0]?.time) ||
                                                (task?.timeDate?.find(
                                                  ({ date }) =>
                                                    date === day?.date
                                                )?.timeEntry[0]
                                                  .timeEntryStatus === "S" &&
                                                  task?.timeDate?.find(
                                                    ({ date }) =>
                                                      date === day?.date
                                                  )?.timeEntry[0]?.time === 0 &&
                                                  !(
                                                    task?.timeDate?.find(
                                                      ({ date }) =>
                                                        date === day?.date
                                                    )?.isFreezed === "Y"
                                                  )) ? (
                                                this.renderInputComponent(
                                                  timeSheet,
                                                  task,
                                                  day
                                                )
                                              ) : (
                                                <span className="">
                                                  <OverlayTrigger
                                                    id={
                                                      "timeRecId: " +
                                                      task?.timeDate?.find(
                                                        ({ date }) =>
                                                          date === day.date
                                                      )?.timeRecordId
                                                    }
                                                    placement="top"
                                                    trigger={["hover", "focus"]}
                                                    // trigger={
                                                    //   task?.timeDate?.find(
                                                    //     ({ date }) =>
                                                    //       date === day?.date
                                                    //   )?.timeEntry[0].focused
                                                    //     ? ["hover", "focus"]
                                                    //     : "hover"
                                                    // }
                                                    overlay={
                                                      <Tooltip
                                                        // id="tooltip"
                                                        id={`tooltip-${task?.timeDate?.find(
                                                          ({ date }) =>
                                                            date === day.date
                                                        )?.timeRecordId
                                                          }`}
                                                        className="time_status_tooltip"
                                                      // style={styleForTooltip}
                                                      >
                                                        {this.renderStatusForTooltip(
                                                          task,
                                                          day
                                                        )}
                                                      </Tooltip>
                                                    }
                                                    onEntering={(e) => {
                                                      this.entering(e);
                                                    }}
                                                  >
                                                    {this.renderInputComponent(
                                                      timeSheet,
                                                      task,
                                                      day
                                                    )}
                                                  </OverlayTrigger>
                                                </span>
                                              )}

                                              {task?.timeDate?.find(
                                                ({ date }) => date === day?.date
                                              )?.timeError && (
                                                  <PopOverForTimeValidation alt="Warning Message, Make sure hours are under 24" />
                                                )}
                                            </Fragment>
                                          </td>
                                        )
                                      )}
                                      {/* </div> */}

                                      <td
                                        className="ch-time-row-total"
                                        tabIndex={0}
                                        title={`${task?.totalTimeForTask} Hours`}
                                      >
                                        {Math.round(
                                          (task?.totalTimeForTask +
                                            Number.EPSILON) *
                                          100
                                        ) / 100}{" "}
                                        Hrs
                                      </td>
                                    </tr>
                                  )
                              )}
                            </div>
                          )
                      )
                    ) : (
                      <div className="notify">
                        {this.props.loading
                          ? "Loading.."
                          : "No Data to Display"}
                      </div>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default TimeEntryMainPage;
