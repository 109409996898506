import React, { Component } from "react";
import "./App.css";
import "./Feedback.css";
import NavBar from "./components/navbar";
import MainMenu from "./components/mainMenu";
import envConfig from "./environment-property/property.json";
import AuthService from "./services/AuthService";
import {
  getDelegatedUsers,
  getErrorMessage,
  getloggedUserDetails,
  setAccessToken,
} from "./utils/utils";
import { getAlerts } from "./utils/services";
import ErrorModel from "./components/menuComponents/commonComponents/errorModel";

class App extends Component {
  constructor() {
    super();
    this.state = {
      error: {
        status: false,
        message: "",
      },
      loggedUserDetails: null,
      isAuthenticated: false,
      isLoading: true,

      delegatedUsers: [],
      loggedUserGteEmployeeNo: 0,
      operationalUserGteEmployeeNo: 0,
      handleSave: false,
      clickedAlert: false,
      alertData: null,
      selectedDate: null,
    };
    this.AuthService = new AuthService();
    this.tokenCacheKey = envConfig.authConfiguration.tokenCacheKey;
  }

  async componentDidMount() {
    try {
      let params = new URL(document.location).searchParams;
      if (params != null && params.get("jwt") != null) {
        // console.log(">>>>>>>>>>>>>>>>>>>>>>>> ",params.get("jwt"))
        let jwt;
        jwt = params.get("jwt");
        setAccessToken(jwt);

        this.setState({
          isAuthenticated: true,
          isLoading: false,
          loggedUserDetails: {
            displayName: getloggedUserDetails().name,
            email: getloggedUserDetails().userName,
          },
        });
        let userName = getloggedUserDetails().userName;
        this.getDelegatedUserList(userName);

        // -----Added for PERF TEST
        // if (envConfig.debug.isDebugEnable) {
        //   let params = new URL(document.location).searchParams;

        //   let displayName;
        //   let email;

        //   if (params != null) {
        //     displayName = params.get("employee");
        //     email = params.get("user-name");

        //     this.setState({
        //       isAuthenticated: true,
        //       isLoading: false,
        //       loggedUserDetails: {
        //         displayName: displayName,
        //         email: email,
        //       },
        //     });
        //     this.getDelegatedUserList(email);
        //   } else {
        //     this.setState({
        //       loggedUserDetails: envConfig.debug.defaultUser,
        //     });
        //   }
        // } else {
        //   this.setState({
        //     isAuthenticated: true,
        //     isLoading: false,
        //     loggedUserDetails: {
        //       displayName: getloggedUserDetails().name,
        //       email: getloggedUserDetails().userName,
        //     },
        //   });
        //   let userName = getloggedUserDetails().userName;
        //   this.getDelegatedUserList(userName);
        // }

        // ---- commented for PERF TEST
        // this.setState({
        //   isAuthenticated: true,
        //   isLoading: false,
        //   loggedUserDetails: {
        //     displayName: getloggedUserDetails().name,
        //     email: getloggedUserDetails().userName,
        //   },
        // });
        // let userName = getloggedUserDetails().userName;
        // this.getDelegatedUserList(userName);
      } else {
        const login = await this.AuthService.initLogin();
        if (login) {
          const accessToken = await this.AuthService.getToken();

          setAccessToken(accessToken);

          if (envConfig.debug.isDebugEnable) {
            let params = new URL(document.location).searchParams;

            let displayName;
            let email;

            if (params != null) {
              displayName = params.get("employee");
              email = params.get("user-name");

              this.setState({
                isAuthenticated: true,
                isLoading: false,
                loggedUserDetails: {
                  displayName: displayName,
                  email: email,
                },
              });
              this.getDelegatedUserList(email);
            } else {
              this.setState({
                loggedUserDetails: envConfig.debug.defaultUser,
              });
            }
          } else {
            this.setState({
              isAuthenticated: true,
              isLoading: false,
              loggedUserDetails: {
                displayName: getloggedUserDetails().name,
                email: getloggedUserDetails().userName,
              },
            });
            let userName = getloggedUserDetails().userName;
            this.getDelegatedUserList(userName);
          }
        }
      }



    } catch (error) {
      let message = getErrorMessage(error);
      this.setState({
        loading: false,
        error: {
          status:
            message?.status === 401
              ? false
              : message?.status === 404
                ? false
                : true,
          message: message,
        },
      });
    }
  }

  handleLogOut = () => {
    this.setState({
      loggedUserDetails: null,
    });
  };

  handleLogIn = () => {
    this.AuthService.login();
  };

  getDelegatedUserList(user) {
    if (!(user === null)) {
      getDelegatedUsers("?user-name=", user)
        .then((response) => {
          this.setState({
            delegatedUsers: response?.data?.delegatedUsers,
            loggedUserGteEmployeeNo: response?.data?.gteEmployeeNo,
            operationalUserGteEmployeeNo: response?.data?.gteEmployeeNo,
          });
        })
        .catch((error) => {
          let message = getErrorMessage(error);
          this.setState({
            loading: false,
            error: {
              status:
                message?.status === 401
                  ? false
                  : message?.status === 404
                    ? false
                    : true,
              message: message,
            },
          });
        });
    }
  }

  handleSaveDraft() {
    this.setState({ handleSave: true });
  }
  updateSelectedDate(date) {
    this.setState({ selectedDate: date });
  }

  renderMainComponent = () => {
    if (this.state.loggedUserDetails !== null) {
      return (
        <MainMenu
          key="main-menu-key"
          setAlerts={(user) => {
            this.getAlertsData(user);
          }}
          error={this.state.error}
          alertData={this.state.alertData}
          loggedUserDetails={this.state.loggedUserDetails}
          delegatedUsers={this.state?.delegatedUsers}
          loggedUserGteEmployeeNo={this.state.loggedUserGteEmployeeNo}
          operationalUserGteEmployeeNo={this.state.operationalUserGteEmployeeNo}
          handleSaveDraft={this.state.handleSave}
          clickedAlert={this.state.clickedAlert}
          updateStateOfClickedAlert={(value) => {
            this.updateStateOfClickedAlert(value);
          }}
          selectedDate={(date) => this.updateSelectedDate(date)}
        />
      );
    } else {
      return (
        <div className="alert alert-light" role="alert">
          {/* You are logout! Please login. */}
        </div>
      );
    }
  };
  updateStateOfClickedAlert = (value) => {
    this.setState({
      clickedAlert: value,
    });
  };

  getAlertsData(operationalUserGteEmployeeNo) {
    const requestId = `REQUEST-${Date.now()}`;
    this.lastRequestId = requestId;

    setTimeout(() => {
      if (this.lastRequestId !== requestId) {
        return;
      } else {
        getAlerts(
          operationalUserGteEmployeeNo,
          this.state.loggedUserGteEmployeeNo
        ).then((array) => {
          this.setState({
            alertData: array,
          });
        });
      }
    }, 1000);
  }

  render() {
    let sortedDelegatedUsers = [];
    if (this.state.delegatedUsers !== null) {
      const delegatedUsers = [...this.state.delegatedUsers]?.sort((a, b) =>
        a.delegatedUserName.localeCompare(b.delegatedUserName)
      );
      sortedDelegatedUsers = delegatedUsers;
    }
    return (
      <React.Fragment>
        <body>
          {/* <div className="ch-alert-mask">
            <span>CheckIn is not support for this screen resolution</span>
          </div> */}
          <NavBar
            key="nav-bar-key"
            alertData={this.state.alertData}
            loggedUserDetails={this.state.loggedUserDetails}
            delegatedUsers={sortedDelegatedUsers}
            loggedUserGteEmployeeNo={this.state.loggedUserGteEmployeeNo}
            operationalUserGteEmployeeNo={
              this.state.operationalUserGteEmployeeNo
            }
            logOut={this.handleLogOut}
            logIn={this.handleLogIn}
            handleDelegatedUser={(
              operationalUserGteEmployeeNo,
              loggedUserGteEmployeeNo
            ) => {
              this.setState({
                gteEmployeeNo: operationalUserGteEmployeeNo,

                loggedUserGteEmployeeNo: loggedUserGteEmployeeNo,
                operationalUserGteEmployeeNo: operationalUserGteEmployeeNo,
              });
            }}
            handleSave={() => this.handleSaveDraft()}
            updateStateOfClickedAlert={(value) => {
              this.updateStateOfClickedAlert(value);
            }}
            selectedDate={this.state.selectedDate}
          />
          <main className="">{this.renderMainComponent()}</main>
          {this.state.error.status && (
            <ErrorModel
              loggedUserDetails={this.props.loggedUserDetails}
              errorResponse={this.state.error}
              hide={() => {
                this.setState({
                  error: {
                    status: false,
                    message: "",
                  },
                });
              }}
            ></ErrorModel>
          )}
        </body>
      </React.Fragment>
    );
  }
}

export default App;
