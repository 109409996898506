import envConfig from "../environment-property/property.json";
import axios from "axios";
import jwt from "jwt-decode";
import AuthService from "../services/AuthService";


const host = envConfig.services.host;

export function setAccessToken(accessToken) {
  const decoded = jwt(accessToken);

  let loggedUser = {
    name: decoded.name,
    userName: decoded.unique_name,
  };

  localStorage.setItem("logged-user", JSON.stringify(loggedUser));
  localStorage.setItem(envConfig.authConfiguration.tokenCacheKey, accessToken);
}

export async function validateAccessToken(accessToken) {
  if (Date.now() >= jwt(accessToken).exp * 1000) {
    let params = new URL(document.location).searchParams;
    if (params.get("source") === "empower") {
      throw new Error("TOKEN_NOT_ELIGIBLE_FOR_REFRESH");
    } else {
      const token_1 = await new AuthService().getToken();
      setAccessToken(token_1);
      return token_1;
    }
   
  } else {
    return new Promise((resolve) => {
      resolve(accessToken);
    });
  }
}

export async function generateHeaderDetails(accessToken, subscriptionKey) {
  const validatedAccessToken = await validateAccessToken(accessToken);
  return {
    headers: {
      Authorization: "Bearer " + validatedAccessToken,
      "Ocp-Apim-Subscription-Key": subscriptionKey,
    },
  };
}
export async function generateHeaderDetailsWithTimeZone(accessToken, subscriptionKey,timeZone) {
  const validatedAccessToken = await validateAccessToken(accessToken);
  return {
    headers: {
      Authorization: "Bearer " + validatedAccessToken,
      "Ocp-Apim-Subscription-Key": subscriptionKey,
      "X-TimeZone":timeZone
    },
  };
}
export function getloggedUserDetails() {
  return JSON.parse(localStorage.getItem("logged-user"));
}

export function getErrorMessage(error) {
  let value =
    "Opps! An Unexpected Error Occured. Please Reach out to EAG Support Team with a Screenshot of this error.  Request ID : ";

  let message = {};
  let requestId = "Not Applicable";
  if (error?.response) {
    requestId = getRequestId(error?.response);

    if (error?.response?.status === 404) {
      message = {
        errorMessage: "No Data To Display",
        status: error?.response?.status,
      };
    } else if (error.response?.status === 401) {
      message = {
        status: error.response?.status,
        message: {
          errorMessage: value + " " + requestId,
          status: error.response?.status,
        },
      };
    } else {
      message = {
        errorMessage: value + " " + requestId,
        status: error?.response?.status,
      };
    }
  } else if (error?.request) {
    requestId = getRequestId(error?.request);

    message = {
      errorMessage: value + " " + requestId,
      status: error?.response?.status,
    };
  } else if(error?.message === "TOKEN_NOT_ELIGIBLE_FOR_REFRESH"){
    console.log("error >>>> ",error?.message)
    message = {
      errorMessage:"Sorry your session expired. Please refresh the page to continue",
      status: 0,
    };
    
  } else {
    message = {
      errorMessage: value + " " + requestId,
      status: 0,
    };
  }

  return message;
}
function getRequestId(value) {
  let requestId = "Not Applicable";
  if (value?.hasOwnProperty("data")) {
    if (value?.data?.hasOwnProperty("message")) {
      var str_pos = value?.data?.message?.indexOf("RequestID");
      if (str_pos > -1) {
        requestId = value?.data?.message?.slice(
          value?.data?.message?.indexOf(":") + 1
        );
      }
    }
  }
  return requestId;
}
export async function getDelegatedUsers(parameter1, value1) {
  const config = await generateHeaderDetails(
    localStorage.getItem(envConfig.authConfiguration.tokenCacheKey),
    envConfig.securityConfiguration.OcpApimSubscriptionKey
  );
  let generalPath = envConfig.services.checkinBffServiceWeb + envConfig.services.timeEntry;
  let functionPath = envConfig.services.users;
  return await axios.get(
    host + generalPath + functionPath + parameter1 + value1,
    config
  );
}

export async function getEmployeeLeaveHolidayData(
  empNum,
  val1,
  year,
  val2,
  week,
  val3,
  userNum,
  val4
) {
  const config = await generateHeaderDetails(
    localStorage.getItem(envConfig.authConfiguration.tokenCacheKey),
    envConfig.securityConfiguration.OcpApimSubscriptionKey
  );

  let generalPath = envConfig.services.checkinBffServiceWeb + envConfig.services.timeEntry;
  let functionPath = envConfig.services.employee+"/"+envConfig.services.leaveHoliday;
  return await axios.get(
    host +
    generalPath +
    functionPath +
    empNum +
    val1 +
    year +
    val2 +
    week +
    val3 +
    userNum +
    val4,
    config
  );
}

export async function getProjectList(
  empNum,
  val1,
  year,
  val2,
  week,
  val3,
  userNum,
  val4
) {
  const config = await generateHeaderDetails(
    localStorage.getItem(envConfig.authConfiguration.tokenCacheKey),
    envConfig.securityConfiguration.OcpApimSubscriptionKey
  );

  let generalPath = envConfig.services.checkinBffServiceWeb + envConfig.services.timeEntry;
  let functionPath = envConfig.services.projects;
  return await axios.get(
    host +
    generalPath +
    functionPath +
    empNum +
    val1 +
    year +
    val2 +
    week +
    val3 +
    userNum +
    val4,
    config
  );
}

export async function getGlobalTasks(isGlobal, val1) {
  const config = await generateHeaderDetails(
    localStorage.getItem(envConfig.authConfiguration.tokenCacheKey),
    envConfig.securityConfiguration.OcpApimSubscriptionKey
  );

  let generalPath = envConfig.services.checkinSourceServiceWeb;
  let functionPath = envConfig.services.standardCodes;
  return await axios.get(
    host + generalPath + functionPath + isGlobal + val1,
    config
  );
}
export async function getFreezeDateValue() {
  const config = await generateHeaderDetails(
    localStorage.getItem(envConfig.authConfiguration.tokenCacheKey),
    envConfig.securityConfiguration.OcpApimSubscriptionKey
  );

  let generalPath = envConfig.services.checkinSourceServiceWeb;
  let functionPath = envConfig.services.config;
  let configType = "?config-type=";
  let freezeDate = "freezeDate";
  return await axios.get(
    host + generalPath + functionPath + configType + freezeDate,
    config
  );
}
export async function getPastWeekLeaveMismatchesData(gteEmpNum,week,year,loggedUser,currentDate) {
  const config = await generateHeaderDetailsWithTimeZone(
    localStorage.getItem(envConfig.authConfiguration.tokenCacheKey),
    envConfig.securityConfiguration.OcpApimSubscriptionKey,
    currentDate
  );

  let generalPath = envConfig.services.host+envConfig.services.checkinBffServiceWeb;
  let functionPath = envConfig.services.leaveCheckinTimeValidation;

  return await axios.get(
    generalPath + functionPath + "gte-employee-no="+gteEmpNum+"&week-no="+week+"&year="+year+"&logged-user-gte-employee-no="+loggedUser,
    config
  );
}
export async function getTimeSheetList(
  empNum,
  val1,
  year,
  val2,
  week,
  val3,
  projectId,
  val4,
  loggedUserId,
  val5,
  currentDate
) {

  const config = await generateHeaderDetailsWithTimeZone(
    localStorage.getItem(envConfig.authConfiguration.tokenCacheKey),
    envConfig.securityConfiguration.OcpApimSubscriptionKey,
    currentDate
  );
  let generalPath = envConfig.services.checkinBffServiceWeb + envConfig.services.timeEntry;
  let functionPath = envConfig.services.timeSheets;
  return await axios.get(
    host +
    generalPath +
    functionPath +
    empNum +
    val1 +
    year +
    val2 +
    week +
    val3 +
    projectId +
    val4 +
    loggedUserId +
    val5,
    config
  );
}

export async function patchMethod(value1) {
  const config = await generateHeaderDetails(
    localStorage.getItem(envConfig.authConfiguration.tokenCacheKey),
    envConfig.securityConfiguration.OcpApimSubscriptionKey
  );
  let generalPath = envConfig.services.checkinBffServiceWeb + envConfig.services.timeEntry;
  let functionPath = envConfig.services.task;
  return await axios.post(host + generalPath + functionPath, value1, config);
}

export async function getUnassignedTasks(
  param1,
  operationalUserId,
  param2,
  year,
  param3,
  week,
  param4,
  projectId,
  param5,
  loggedUserId
) {
  const config = await generateHeaderDetails(
    localStorage.getItem(envConfig.authConfiguration.tokenCacheKey),
    envConfig.securityConfiguration.OcpApimSubscriptionKey
  );

  let generalPath = envConfig.services.checkinBffServiceWeb + envConfig.services.timeEntry;
  let functionPath = envConfig.services.tasks;
  return await axios.get(
    host +
    generalPath +
    functionPath +
    param1 +
    operationalUserId +
    param2 +
    year +
    param3 +
    week +
    param4 +
    projectId +
    param5 +
    loggedUserId,
    config
  );
}

export async function deleteSelectedTaskData(value1) {
  const config = await generateHeaderDetails(
    localStorage.getItem(envConfig.authConfiguration.tokenCacheKey),
    envConfig.securityConfiguration.OcpApimSubscriptionKey
  );

  let headers = config.headers;
  let generalPath = envConfig.services.checkinBffServiceWeb + envConfig.services.timeEntry;
  let functionPath = envConfig.services.tasks;
  return await axios.delete(host + generalPath + functionPath, {
    headers,
    data: value1,
  });
}

export async function getDayWiseTotal(
  param1,
  employeeNum,
  param2,
  year,
  param3,
  week,
  param4,
  loggedUserId
) {
  const config = await generateHeaderDetails(
    localStorage.getItem(envConfig.authConfiguration.tokenCacheKey),
    envConfig.securityConfiguration.OcpApimSubscriptionKey
  );

  let generalPath = envConfig.services.checkinBffServiceWeb + envConfig.services.timeEntry;
  let functionPath = envConfig.services.dailyTotal;
  return await axios.get(
    host +
    generalPath +
    functionPath +
    param1 +
    employeeNum +
    param2 +
    year +
    param3 +
    week +
    param4 +
    loggedUserId,
    config
  );
}

export async function saveOrSubmitPatchMethod(year, week, param1, value1,timeZone) {
  const config = await generateHeaderDetailsWithTimeZone(
    localStorage.getItem(envConfig.authConfiguration.tokenCacheKey),
    envConfig.securityConfiguration.OcpApimSubscriptionKey,
    timeZone
  );

  let generalPath = envConfig.services.checkinBffServiceWeb + envConfig.services.timeEntry;
  return await axios.patch(
    host + generalPath + year + "/" + week + "/" + param1,
    value1,
    config
  );
}

export async function taskDonePatchMethod(taskAssignmentId, value1) {
  const config = await generateHeaderDetails(
    localStorage.getItem(envConfig.authConfiguration.tokenCacheKey),
    envConfig.securityConfiguration.OcpApimSubscriptionKey
  );

  let generalPath = envConfig.services.checkinSourceServiceWeb;
  let functionPath = envConfig.services.taskAssignments;
  return await axios.patch(
    host + generalPath + functionPath + "/" + taskAssignmentId,
    value1,
    config
  );
}

export async function getAlertsMethod(
  param1,
  operationalUserGteEmployeeNo,
  param2,
  loggedUserGteEmployeeNo
) {
  const config = await generateHeaderDetails(
    localStorage.getItem(envConfig.authConfiguration.tokenCacheKey),
    envConfig.securityConfiguration.OcpApimSubscriptionKey
  );

  let generalPath = envConfig.services.checkinBffServiceWeb + envConfig.services.timeEntry;
  let functionPath = envConfig.services.alerts;
  let res = axios.get(
    host +
    generalPath +
    functionPath +
    param1 +
    operationalUserGteEmployeeNo +
    param2 +
    loggedUserGteEmployeeNo,

    config
  );
  return await res;
}
export async function unFreezeMyWeek(value1) {
  const config = await generateHeaderDetails(
    localStorage.getItem(envConfig.authConfiguration.tokenCacheKey),
    envConfig.securityConfiguration.OcpApimSubscriptionKey
  );

  let generalPath = envConfig.services.checkinBffServiceWeb + envConfig.services.timeEntry;
  let functionPath = envConfig.services.unfreezeMyWeek;
  return await axios.post(host + generalPath + functionPath, value1, config);
}

export async function copyWeekMethod(
  param1,
  gteEmpNum,
  param2,
  year,
  param3,
  week,
  param4,
  loggedUserGteEmployeeNo,
  currentDate
) {
  // const config = await generateHeaderDetails(localStorage.getItem(envConfig.authConfiguration.tokenCacheKey), envConfig.securityConfiguration.OcpApimSubscriptionKey);
  const config = await generateHeaderDetailsWithTimeZone(
    localStorage.getItem(envConfig.authConfiguration.tokenCacheKey),
    envConfig.securityConfiguration.OcpApimSubscriptionKey,
    currentDate
  );
  
  let generalPath = envConfig.services.checkinBffServiceWeb + envConfig.services.timeEntry;
  let functionPath = envConfig.services.copyWeek;
  let res = axios.post(
    host +
    generalPath +
    functionPath +
    param1 +
    gteEmpNum +
    param2 +
    year +
    param3 +
    week +
    param4 +
    loggedUserGteEmployeeNo,
    {},
    config
  );
  return await res;
}
export async function copyUnAssignTaskMethod(
  param1,
  gteEmpNum,
  param2,
  year,
  param3,
  week,
  param4,
  loggedUserGteEmployeeNo
) {
  const config = await generateHeaderDetails(localStorage.getItem(envConfig.authConfiguration.tokenCacheKey), envConfig.securityConfiguration.OcpApimSubscriptionKey);

  let generalPath = envConfig.services.checkinBffServiceWeb + envConfig.services.timeEntry;
  let functionPath = envConfig.services.copyUnAssignTask;
  let res = axios.post(
    host +
    generalPath +
    functionPath +
    param1 +
    gteEmpNum +
    param2 +
    year +
    param3 +
    week +
    param4 +
    loggedUserGteEmployeeNo,
    {},
    config
  );
  return await res;
}

export async function getApplicationGuideInforLink() {
  const config = await generateHeaderDetails(localStorage.getItem(envConfig.authConfiguration.tokenCacheKey), envConfig.securityConfiguration.OcpApimSubscriptionKey);

  let res = axios.get(
    host +
    envConfig.services.checkinSourceServiceWeb +
    envConfig.services.config +
    envConfig.services.configType +
    envConfig.services.inforPage,
    config
  );
  return await res;
}
export async function postFeedback(user_id, value) {

  const config = await generateHeaderDetails(localStorage.getItem(envConfig.authConfiguration.tokenCacheKey), envConfig.securityConfiguration.OcpApimSubscriptionKey_for_feedback);

  return await axios.post(
    // envConfig.services.feedback_host +
    // envConfig.services.virtusa_generic_feedback_crud_api +
    // envConfig.services.feedback +
    // envConfig.services.user_feedback,
    envConfig.services.user_feedback_uri,
    value, config
  );

}
export const sendFeedback = (user_id, value) => {
  return new Promise((resolve) => {
    resolve(
      postFeedback(user_id, value).then((res) => {
        return res.data;
      })
    );
  });
};