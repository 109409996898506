import React, { Component, Fragment } from "react";
import BannerImageURL from "../../logo/banner.png";
import envConfig from "../../environment-property/property.json";

class banner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bannerVisible: envConfig.banner.showBanner,
      feedbackVisible: envConfig.showFeedback,
      toast: false,
    };
  }

  handleBannerClose = () => {
    this.setState({ bannerVisible: false });
    this.props.bannerVisible(false);
  };

  render() {
    const { bannerVisible } = this.state;

    let modelStyle = {
      display: bannerVisible ? "block" : "none",
      backgroundColor: "rgba(0,0,0,0.8)",
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
    };

    return (
      <div>
        <Fragment>
          <div className="ch-notif-banner">
            <div className="banner modal show " style={modelStyle}>
              <div className="banner modal-dialog modal-width">
                <div className="banner modal-content">
                  <button
                    type="button"
                    className="banner btn-close"
                    onClick={this.handleBannerClose}
                  ></button>
                  <div>
                    <div className="bannermodal-body">
                      {/* <a href={envConfig.banner.bannerLink} target="_blank"> */}
                        <img src={BannerImageURL} alt="CheckIn" />
                      {/* </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      </div>
    );
  }
}

export default banner;
